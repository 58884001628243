import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Bio from "../components/Bio";

import styles from "../styles/blog.post.module.scss";

export default ({ data }) => {
    const post = data.mdx;

    return (
        <Layout>
            <Seo
                title={post.frontmatter.title}
                description={post.frontmatter.description || post.excerpt}
            />

            <div className={styles.content}>
                <div className={styles.title}>
                    <h2 className={styles.text}>{post.frontmatter.title}</h2>
                    <h4 className={styles.desc}>
                        {post.frontmatter.date} - {post.fields.readingTime.text}
                    </h4>
                </div>
                <MDXRenderer>{post.body}</MDXRenderer>
            </div>

            <div className={styles.bioContainer}>
                <Bio
                    style={{
                        marginBottom: "0"
                    }}
                />
            </div>
        </Layout>
    );
};

export const pageQuery = graphql`
    query($path: String!) {
        mdx(frontmatter: { path: { eq: $path } }) {
            id
            body
            excerpt(pruneLength: 160)
            frontmatter {
                date(formatString: "DD MMMM, YYYY")
                path
                title
            }
            fields {
                readingTime {
                    text
                }
            }
        }
    }
`;
